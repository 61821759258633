import React from "react"
import Home from "./routes/Home"

function LivelyApp() {
  return (
      <Home />
  );
}

export default LivelyApp
