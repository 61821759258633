import React from "react"
import useWindowDimensions from "../../useWindowDimensions"

import BlogContactSection from "../../components/BlogContactSection"

import colors from "../../styles/colors.js"
import containers from "../../styles/containers.js"
import sizing from "../../styles/sizing.js"
import spacing from "../../styles/spacing.js"
import text from "../../styles/text.js"

// rules to format blog posts
    // use styles.space3Above on text just below title
    // use styles.space3Above to divide bigger sections
    // use styles.space1Above to divide individual paragraphs

    // use className="h4" and styles.subtitle on date tag
    // use className="h1" on title, may need to do inline styling to set a good width
    // use className="h3" on subheadings
    // use className="p" and styles.space1Above on all paragraphs

    // always leave the contact info section at the bottom

    // here's a dash symbol if needed! —

const IosAppLaunch = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  document.title = "Lively iOS App Store Launch! | Lively Blog"

  const { height, width } = useWindowDimensions()

  const widthControl = () => {
    if (width > 1100) {
      return {
        maxWidth: "76rem",
        display: "flex",
        flexDirection: "column",
      }
    } else {
      return {
        display: "flex",
        flexDirection: "column",
      }
    }
  }

  const imageControl = () => {
    if (width > 900) {
      return {
        maxWidth: "70%",
        marginBottom: "4rem",
      }
    } else {
      return {
        maxWidth: "100%",
        marginBottom: "4rem",
      }
    }
  }


  return (
    <div className="fixed-flex-column">
      <div className="fixed-flex-column-ca padding-642">
        <div style={widthControl()}>
            <img style={imageControl()} src={process.env.PUBLIC_URL + "/assets/blog/celebrate-app-release.png"} alt={"Confetti"} />
            <p className="h4" style={styles.subtitle}>SEPTEMBER 01, 2023</p>
            <p className="h1" style={{maxWidth: "72rem"}}>
              The Lively app is available on the App Store!
            </p>

            <p className="p" style={styles.space3Above}>
              <a className="bold">We've finally reached the finish line!</a> After months 
              of brainstorming, designing, coding, and testing, Lively is now a fully-functional 
              mobile app. But, we're already working on our next update, so I guess this is only 
              the beginning. We can't wait to continue bringing new features to the app.
            </p>
            <p className="p" style={styles.space3Above}>
              Thanks for hopping on this boat with us and joining in on our adventure.
            </p>
            
            <BlogContactSection />
        </div>
      </div>

    </div>
  )
}

export default IosAppLaunch

let styles = {
  subtitle: {
    color: colors.darkGray,
  },
  bold: {
    fontWeight: "600"
  },
  space6Above: {
    marginTop: "6rem",
  },
  space3Above: {
    marginTop: "3rem",
  },
  space1Above: {
    marginTop: "1rem",
  },
}