import React from "react"
import useWindowDimensions from "../../useWindowDimensions"

import BlogContactSection from "../../components/BlogContactSection"

import colors from "../../styles/colors.js"
import containers from "../../styles/containers.js"
import sizing from "../../styles/sizing.js"
import spacing from "../../styles/spacing.js"
import text from "../../styles/text.js"

// rules to format blog posts
    // use styles.space3Above on text just below title
    // use styles.space3Above to divide bigger sections
    // use styles.space1Above to divide individual paragraphs

    // use className="h4" and styles.subtitle on date tag
    // use className="h1" on title, may need to do inline styling to set a good width
    // use className="h3" on subheadings
    // use className="p" and styles.space1Above on all paragraphs

    // always leave the contact info section at the bottom

    // here's a dash symbol if needed! —

const CongressionalApp = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  document.title = "Congressional App Challenge Recognition | Lively Blog"

  const { height, width } = useWindowDimensions()

  const widthControl = () => {
    if (width > 1100) {
      return {
        maxWidth: "76rem",
        display: "flex",
        flexDirection: "column",
      }
    } else {
      return {
        display: "flex",
        flexDirection: "column",
      }
    }
  }

  const imageControl = () => {
    if (width > 900) {
      return {
        maxWidth: "70%",
        marginBottom: "4rem",
      }
    } else {
      return {
        maxWidth: "100%",
        marginBottom: "4rem",
      }
    }
  }

  return (
    <div className="fixed-flex-column">
      <div className="fixed-flex-column-ca padding-642">
        <div style={widthControl()}>
            <img style={imageControl()} src={process.env.PUBLIC_URL + "/assets/blog/congressional-app-challenge.png"} alt={"Congress building and banner"} />
            <p className="h4" style={styles.subtitle}>DECEMBER 20, 2022</p>
            <p className="h1" style={{maxWidth: "72rem"}}>
              Lively was recognized in the Congressional App Challenge!
            </p>

            <p className="p" style={styles.space3Above}>
              We’re so excited to announce that Lively earned first place in our 
              district’s Congressional App Challenge! Out of thousands of entries 
              coming from students across the nation, Lively was one of the top 335 
              apps selected this year. The judging panel awarded Lively for its 
              beautiful user interface, functional design, and choice of technologies.
            </p>
            <p className="p" style={styles.space3Above}>
              If you’d like to learn more, Lively is featured <a className="blue-to-black-link" href="https://www.congressionalappchallenge.us/22-MO02/" target="_blank">here</a> on 
              the U.S. House of Representatives website, and it will also be on display 
              in an exhibit in the U.S. Capitol for a year. Also, stay tuned for April 18th—we’ll 
              be presenting at the 2023 #HouseOfCode event in Washington D.C.!
            </p>
            <p className="p" style={styles.space3Above}>
            We’re so grateful for all these opportunities, and we hope you love using
            Lively to streamline the planning, organizing, and reflecting you do each day. 
            Thanks for joining us on this journey and helping us make Lively grow.
            </p>
            
            <BlogContactSection />
        </div>
      </div>

    </div>
  )
}

export default CongressionalApp

let styles = {
  subtitle: {
    color: colors.darkGray,
  },
  bold: {
    fontWeight: "600"
  },
  space6Above: {
    marginTop: "6rem",
  },
  space3Above: {
    marginTop: "3rem",
  },
  space1Above: {
    marginTop: "1rem",
  },
}