import React from "react"
import { Link } from "react-router-dom"
import useWindowDimensions from "../useWindowDimensions"

import colors from "../styles/colors.js"
import containers from "../styles/containers.js"
import sizing from "../styles/sizing.js"
import spacing from "../styles/spacing.js"
import text from "../styles/text.js"

const Blog = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const { height, width } = useWindowDimensions()

  React.useEffect(() => {
    const favicon = document.getElementById('favicon')
    favicon.href = "/favicon-lively.ico"
  }, [])
  document.title = "Blog | Lively"

  const reverseWidthControl = () => {
    if (width > 700) {
      return {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        borderRadius: "2rem",
        maxHeight: "32rem",
        backgroundColor: colors.grayBackground,
        overflow: "hidden",
        justifyContent: "space-between"
      }
    } else {
      return {
        display: "flex",
        flexDirection: "column-reverse",
        alignItems: "flex-start",
        borderRadius: "2rem",
        backgroundColor: colors.grayBackground,
        overflow: "hidden",
        justifyContent: "space-between"
      }
    }
  }

  const contentSpacingControl = () => {
    if (width > 900) {
      return {
        marginLeft: "4rem",
        marginBottom: 0,
      }
    } else if (width > 700) {
      return {
        marginLeft: "2rem",
        marginBottom: 0,
      }
    } else {
      return {
        marginLeft: 0,
        marginBottom: "2rem",
      }
    }
  }

  const descriptorControl = () => {
    if (width > 900) {
      return {
        maxWidth: "70%",
      }
    } else if (width > 700) {
      return {
        maxWidth: "50%",
      }
    } else {
      return {
        maxWidth: "100%",
      }
    }
  }

  const postCaption = () => {
    if (width > 700) {
      return {
        alignSelf: "center"
      }
    } else {
      return {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start"
      }
    }
  }

  const imageControl = () => {
    if (width > 900) {
      return {
        maxWidth: "30%",
        height: "100%",
      }
    } else if (width > 700) {
      return {
        maxWidth: "50%",
        height: "100%",
      }
    }else if (width > 500) {
      return {
        maxWidth: "50%",
        height: "100%",
      }
    } else {
      return {
        maxWidth: "100%",
      }
    }
  }

  return (
    <div className="fixed-flex-column">
      <div className="padding-42" style={{...styles.blueContainer, textAlign: "center"}}>
        <p className="h1">Welcome to the blog</p>
        <p style={{...styles.spaceAbove}} className="p">
          Keeping you posted from the crow's nest and sharing all the gems we find.
        </p>
      </div>
      
      <div className="fixed-flex-column-ca padding-642">
        <div className="fixed-flex-column max-width-76">
          
        <Link to="/lively/blog/09012023" className="black-to-blue-link margin-bottom-642">
        <div className="padding-42" style={{...reverseWidthControl()}}>
              <div style={{...postCaption(), ...descriptorControl()}}>
                <p className="h4" style={styles.subtitle}>SEPTEMBER 01, 2023</p>
                <p className="black-to-blue-link h3" style={{...styles.spaceAbove}}>
                The Lively app is available on the App Store!
                </p>
              </div>
              <img style={{...imageControl(), ...contentSpacingControl()}} src={process.env.PUBLIC_URL + "/assets/blog/celebrate-app-release.png"} alt={"Confetti"} />
            </div>
          </Link>
          
          <Link to="/lively/blog/08302023" className="black-to-blue-link margin-bottom-642">
          <div className="padding-42" style={{...reverseWidthControl()}}>
              <div style={{...postCaption(), ...descriptorControl()}}>
                <p className="h4" style={styles.subtitle}>AUGUST 30, 2023</p>
                <p className="black-to-blue-link h3" style={{...styles.spaceAbove}}>
                  Coming soon: we're releasing Lively on iPhone!
                </p>
              </div>
              <img style={{...imageControl(), ...contentSpacingControl()}} src={process.env.PUBLIC_URL + "/assets/blog/ios-pre-release-announcement.png"} alt={"Code with gears"} />
            </div>
          </Link>

          <Link to="/lively/blog/12202022" className="black-to-blue-link margin-bottom-642">
          <div className="padding-42" style={{...reverseWidthControl()}}>
              <div style={{...postCaption(), ...descriptorControl()}}>
                <p className="h4" style={styles.subtitle}>DECEMBER 20, 2022</p>
                <p className="black-to-blue-link h3" style={{...styles.spaceAbove}}>
                  Congressional App Challenge win
                </p>
              </div>
              <img style={{...imageControl(), ...contentSpacingControl()}} src={process.env.PUBLIC_URL + "/assets/blog/congressional-app-challenge.png"} alt={"Congress building and banner"} />
            </div>
          </Link>

          <Link to="/lively/blog/12012022" className="black-to-blue-link margin-bottom-642">
          <div className="padding-42" style={{...reverseWidthControl()}}>
              <div style={{...postCaption(), ...descriptorControl()}}>
                <p className="h4" style={styles.subtitle}>DECEMBER 01, 2022</p>
                <p className="black-to-blue-link h3" style={{...styles.spaceAbove}}>
                  A pre-release reflection
                </p>
              </div>
              <img style={{...imageControl(), ...contentSpacingControl()}} src={process.env.PUBLIC_URL + "/assets/blog/pre-release-reflection.png"} alt={"Thinking face"} />
            </div>
          </Link>

        </div>
      </div>

    </div>
  )
}

export default Blog

let styles = {
  blueContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: colors.superLightBlue,
    alignItems: "center",
  },
  subtitle: {
    color: colors.darkGray,
  },
  spaceAbove: {
    marginTop: "1rem",
  },
  postCaption: {
    alignSelf: "center",
  },
}