import React from "react"
import { Link } from "react-router-dom"
import useWindowDimensions from "../useWindowDimensions"

import about from "../styles/about.scss"
import colors from "../styles/colors.js"
import containers from "../styles/containers.js"
import sizing from "../styles/sizing.js"
import spacing from "../styles/spacing.js"
import text from "../styles/text.js"

const About = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  React.useEffect(() => {
    const favicon = document.getElementById('favicon')
    favicon.href = "/favicon-lively.ico"
  }, [])
  document.title = "About Our Story | Lively"
  
  const { height, width } = useWindowDimensions()

  const widthControl = () => {
    if (width > 1300) {
      return {
        maxWidth: "76rem",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center"
      }
    } else {
      return {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }
    }
  }

  const imageControl = () => {
    if (width > 1300) {
      return {
        maxWidth: "36rem",
        marginLeft: "4rem"
      }
    } else if (width > 700) {
      return {
        maxWidth: "75%",
       marginTop: "4rem"
      }
    } else {
      return {
        maxWidth: "100%",
        marginTop: "3rem"
      }
    }
  }

  return (
    <div className="fixed-flex-column">
      <div className="fixed-flex-column-ca padding-642 super-light-blue-fill">
        <div className="fixed-flex-row-ca" style={widthControl()}>
          <div style={{...styles.textWrap}}>
            <p className="h2 margin-bottom-1">
              Staying afloat in a world of turbulence
            </p>
            <p className="p">
              Lively is an <span style={{fontWeight: "600"}}> all-in-one 
              planner and journal</span> that serves as an anchor for 
              people to find balance and purpose in their fast-paced lives.
            </p>
          </div>
          <img
            style={imageControl()}
            src={"../assets/center-small-ship-waves.png"}
            alt={"Ship with waves"}
          />
        </div>
      </div>

      {/* <div style={styles.grayContainerRow}>
        <div style={{}}>
          <div style={styles.textWrap}>
            <p className="h2 margin-bottom-1">
              Get just the essentials with Lively Plus
            </p>
            <p className="h5">
              Lively Plus sticks to the basic add-ons: widgets, themes, advanced 
              insights, and unlimited spaces for more customizability.
            </p>

            <div style={{}}>
              <Link to="/lively/login">
                <div style={{marginLeft: "1rem", marginRight: "1rem"}}>
                  <p className="p">$39.99 per year</p>
                  {width > 500 && <p style={styles.discountOffer}>16% off</p>}
                </div>
              </Link>
              <Link to="/lively/login" className="" style={{maxWidth: "14.16rem", minWidth: "14.16rem"}}>
                <div className="p" style={{marginLeft: "1rem", marginRight: "1rem", display: "flex"}}>
                  <p>$3.99 per month</p>
                </div>
              </Link>
            </div>
          </div>

          <img
            style={{}}
            src={process.env.PUBLIC_URL + "/assets/crowned-lively.png"}
            alt={"Lively paper boat wearing crown"}
          />
        </div>
      </div> */}

      <div className="fixed-flex-column-ca padding-642">
        <div className="max-width-76">
          <p className="h2 margin-bottom-1">
            Lively's story
          </p>
          <p className="p margin-bottom-1 max-width-76">
            In a life flooded with activities, expectations, and distractions, 
            it's hard to stay organized. Many lifestyle and productivity apps 
            exist that try to address this issue, but each one serves different 
            functions — to-do lists, calendars, diaries, docs… It’s not much easier 
            to keep track of things when your thoughts and plans are divided into 
            multiple pieces.
          </p>
          <p className="p padding-bottom-642 max-width-76">
            With this problem in mind, we created Lively to replace 
            cluttered home screens with a single powerful app. Our app's clean and 
            light interface allows you to schedule personal and shared events, write 
            in a journal, and more—all without feeling overwhelmed. With 
            the <span style={{fontWeight: "600"}}>perfect blend of structure and 
            flexibility</span> that Lively gives you, you'll finally be able to 
            find balance and purpose in each day.
          </p>

          <p className="h2 margin-bottom-1">
            Our purpose
          </p>
          <p className="p padding-bottom-642 max-width-76">
            Lively is about giving people the true clarity they need to 
            navigate life's challenges. We want to make it easier 
            to focus on the present moment so you'll spend less time stressing 
            about the future, and we want to provide a personal space for you 
            to organize your activities and reflect on your experiences 
            and wellbeing.
          </p>

          <p className="h2 margin-bottom-1">
            Our team
          </p>
          <p className="p max-width-76">
            Since our launch in early 2022, Bubbly Studios has been dedicated to enriching 
            life through our intuitive apps. At the heart of our team is a passion for 
            creating software that is both beautifully simple and practical. 
            Ultimately, we strive to merge the design and development processes and prioritize
            user-centric experiences above all.
          </p>
          <div className="pfp-section padding-bottom-642">
            <div 
              className="dynamic-flex-rcr-ca"
              style={{justifyContent: "flex-start"}}
            >
              <img
                className="dynamic-pfp"
                src={process.env.PUBLIC_URL + "/assets/serena-pfp.jpeg"}
                alt={"Serena"}
              />
              <div className="text">
                <p className="h3">Serena</p>
                <p className="p italicize">Lead Mobile App Developer</p>
              </div>
            </div>

            <div 
              className="dynamic-flex-rcr-ca"
              style={{justifyContent: "flex-start"}}
            >
              <img
                className="dynamic-pfp"
                src={process.env.PUBLIC_URL + "/assets/emily-pfp.jpeg"}
                alt={"Emily"}
              />
              <div className="text">
                <p className="h3">Emily</p>
                <p className="p italicize">Lead Designer and Web Developer</p>
              </div>
            </div>
          </div>

          <p className="h2 margin-bottom-1">
            Contact us
          </p>
          <p className="p margin-bottom-1 max-width-76">
            We'd love to hear all the feedback, burning questions, and brilliant 
            ideas you have to share! Please feel free to reach out to us at 
            lively@bubblystudios.com, and if you have more of a generic question 
            related to our company as a whole, send you comments to 
            contact@bubblystudios.com.
          </p>
          <p className="p margin-bottom-1 max-width-76">
            Thanks for joining us on this journey!
          </p>
        </div>
      </div>

    </div>
  )
}

export default About

let styles = {
  textWrap: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "flex-start",
  }
}