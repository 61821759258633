import React from "react"
import useWindowDimensions from "../useWindowDimensions"

import colors from "../styles/colors.js"
import containers from "../styles/containers.js"
import sizing from "../styles/sizing.js"
import spacing from "../styles/spacing.js"
import text from "../styles/text.js"

const Support = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  React.useEffect(() => {
    const favicon = document.getElementById('favicon')
    favicon.href = "/favicon-lively.ico"
  }, [])
  document.title = "Support | Lively"

  const { height, width } = useWindowDimensions()

  return (
    <div className="fixed-flex-column">
      <div className="padding-642 center-text" style={{...styles.blueContainer}}>
        <p className="h4" style={styles.subtitle}>LIVELY SUPPORT</p>
        <p className="h1">How can we help you?</p>
        <p style={{...styles.spaceAbove}} className="p">
          If you're looking for something, you're in the right place.
        </p>
      </div>
      
      <div className="fixed-flex-column-ca padding-642">
        <div className="fixed-flex-column max-width-76">
          <p className="h4" style={styles.subtitle}>SELF-SERVE HELP</p>
          <p className="h2">Browse topics</p>

          <p className="h3" style={{...styles.bigSpaceAbove}}>Group events and adding friends</p>
          <p className="p" style={styles.spaceAbove}>
            Lively gives you the flexibility to share events with other users. When creating a new event, 
            the "Share event" property allows you to add other people to that event. If you get invited to an 
            event, accepting the invitation in your notifications tab adds it to your calendar under the 
            "Friend events" space.
          </p>
          <p className="p" style={styles.spaceAbove}>
            Adding friends is another way to connect with people on Lively. Click on the profile icon in the 
            top-right corner of the "Home" tab. The "Friends" tab under settings allows you to manage all your 
            friends and pending friend requests, and you can also search for new friends by their username.
          </p>

          <p className="h3" style={{...styles.bigSpaceAbove}}>How to add new calendar events and journal entries</p>
          <p className="p" style={styles.spaceAbove}>
            To add events or entries, navigate to the "Events" or "Journal" tab. Click 
            the big plus button in the lower right hand corner. After creation, the date 
            property of your new event or entry will be set as the selected date.
          </p>

          <p className="h3" style={{...styles.bigSpaceAbove}}>Options to view your calendar</p>
          <p className="p" style={styles.spaceAbove}>
            Your calendar comes in two different views. In "Timeline" view, your events appear as blocks 
            on a grid. In "Schedule" view, events take on more of a list appearance. You can easily switch 
            between these viewing options by tapping on the hamburger menu in the top-left corner, depending 
            on what works best for you. Tapping on any event pulls up more details and lets you edit that 
            event.
          </p>
          <p className="p" style={styles.spaceAbove}>
            You also get to categorize your events under spaces to stay more organized! Show and hide spaces and 
            all the events associated with that space at any time.
          </p>

          <p className="h3" style={{...styles.bigSpaceAbove}}>Options to view your journal</p>
          <p className="p" style={styles.spaceAbove}>
            When viewing your journal, you can sort entries from newest to oldest, or from oldest to newest. 
            You can easily switch between these viewing options by tapping on the hamburger menu in the 
            top-left corner, depending on what works best for you. When you select on any existing journal 
            entry, you'll be able to view the actual content of that entry.
          </p>
          <p className="p" style={styles.spaceAbove}>
            Not just that, you can choose various time frames depending on if you want to see all your journal entries 
            at once, if you want to reflect on your past week or month, or if you just want to focus in on today.
          </p>

          <p className="h3" style={{...styles.bigSpaceAbove}}>How to edit existing calendar events and journal entries</p>
          <p className="p" style={styles.spaceAbove}>
            Navigate into an event or entry by tapping on the title. From there, you can edit text 
            details of your selection by tapping on the field you want to change. If you want to change 
            the image you uploaded into a journal entry, tap the three white dots in the upper right 
            hand corner of the image.
          </p>

          <p className="h3" style={{...styles.bigSpaceAbove}}>Customizing your profile</p>
          <p className="p" style={styles.spaceAbove}>
            On the "Home" tab, tap on the settings icon in the top-right corner. The "Account" settings tab 
            allows you to change profile picture. You can't change your name, username, or email just yet, but 
            look out for our next update to see when those features become available!
            {/* There, you can also manage social integrations. */}
          </p>

          {/* <p className="h3" style={{...styles.bigSpaceAbove}}>Curating your home feed</p>
          <p className="p" style={styles.spaceAbove}>
            Premium users can add fun and practical additions to their home feed, which 
            includes daily quotes, weather information, and self-care suggestions. You can customize what 
            shows up in your home feed by clicking on the profile icon in the top-right corner of the "Home" 
            tab. Visit the "Custom home feed" settings tab and toggle on and off the content you would 
            like to see.
          </p>

          <p className="h3" style={{...styles.bigSpaceAbove}}>Managing your Premium or Plus plan</p>
          <p className="p" style={styles.spaceAbove}>
            You can manage any active Lively plans you have purchased directly in the 
            app. Navigate to the "Lively Premium" tab under your  settings.
          </p> */}
        
          <p className="h4 margin-top-642" style={{...styles.subtitle}}>CONTACT</p>
          <p className="h2">Couldn't find what you were looking for?</p>
          <p className="p" style={{...styles.spaceAbove, marginBottom: "3rem"}}>
            For any inquiries related specifically to Lively, please 
            contact <a className="blue-to-black-link" href="mailto:lively@bubblystudios.com" target="_blank">lively@bubblystudios.com</a>.
            For other general questions or needs relating to 
            our company as a whole, you can reach us 
            at <a className="blue-to-black-link" href="mailto:contact@bubblystudios.com/" target="_blank">contact@bubblystudios.com</a>. 
            We love hearing what you have to say!
          </p>
        </div>
      </div>

    </div>
  )
}

export default Support

let styles = {
  blueContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: colors.superLightBlue,
    alignItems: "center",
  },
  subtitle: {
    color: colors.darkGray,
  },
  bigSpaceAbove: {
    marginTop: "3rem",
  },
  spaceAbove: {
    marginTop: "1rem",
  },
}