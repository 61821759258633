import React from "react"
import ReactDOM from "react-dom/client"
import "./styles/index.scss"
import { BrowserRouter, Routes, Route } from "react-router-dom"

import BubblyHome from "./BubblyHome"

import LivelyApp from "./LivelyApp"

import SignUp from "./routes/SignUp"
import LogIn from "./routes/LogIn"
import Username from "./routes/Username"
import ForgotPassword from "./routes/ForgotPassword"
import ResetPassword from "./routes/ResetPassword"

import Premium from "./routes/Premium"
import Support from "./routes/Support"
import Blog from "./routes/Blog"
import Download from "./routes/Download"
import About from "./routes/About"
import Error from "./routes/Error"
import Terms from "./routes/Terms"
import Privacy from "./routes/Privacy"
import ShowNavFooter from "./components/ShowNavFooter"

import Account from "./routes/dashboard/Account"

import IosPreReleaseAnnouncement from "./routes/blog-posts/IosPreReleaseAnnouncement"
import PreReleaseReflection from "./routes/blog-posts/PreReleaseReflection"
import CongressionalApp from "./routes/blog-posts/CongressionalApp"
import IosAppLaunch from "./routes/blog-posts/IosAppLaunch"

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<BubblyHome />} />
        <Route path="*" element={<Error />} />
        
        <Route path="/lively" element={<ShowNavFooter children={<LivelyApp />} />} />
        <Route path="/lively/*" element={<ShowNavFooter children={<Error />} />} />

        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />

        <Route path="/lively/signup" element={<SignUp />}  />
        <Route path="/lively/login" element={<LogIn />}  />
        <Route path="/lively/username" element={<Username />}  />
        <Route path="/lively/forgotpassword" element={<ForgotPassword />} />
        {/* <Route path="/lively/resetpassword" element={<ResetPassword />} /> */}

        <Route path="/lively/premium" element={<ShowNavFooter children={<Premium />} />} />
        <Route path="/lively/support" element={<ShowNavFooter children={<Support />} />} />
        <Route path="/lively/blog" element={<ShowNavFooter children={<Blog />} />} />
        <Route path="/lively/download" element={<ShowNavFooter children={<Download />} />} />
        <Route path="/lively/about" element={<ShowNavFooter children={<About />} />} />

        <Route path="/lively/dashboard/" element={<ShowNavFooter children={<Account />} />} exact/>

        <Route path="/lively/blog/12012022" element={<ShowNavFooter children={<PreReleaseReflection />} />} />
        <Route path="/lively/blog/12202022" element={<ShowNavFooter children={<CongressionalApp />} />} />
        <Route path="/lively/blog/08302023" element={<ShowNavFooter children={<IosPreReleaseAnnouncement />} />} />
        <Route path="/lively/blog/09012023" element={<ShowNavFooter children={<IosAppLaunch />} />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
)
