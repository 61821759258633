import React, { useEffect, useRef, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { db, auth } from "../../firebase"
import { doc, getDoc } from "firebase/firestore"
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import useWindowDimensions from "../../useWindowDimensions"

import colors from "../../styles/colors.js"
import containers from "../../styles/containers.js"
import sizing from "../../styles/sizing.js"
import spacing from "../../styles/spacing.js"
import text from "../../styles/text.js"

const Account = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
    const favicon = document.getElementById('favicon')
    favicon.href = "/favicon-lively.ico"
  }, [])
  document.title = "User Dashboard | Lively"

  const { height, width } = useWindowDimensions()

  const location = useLocation()
  const navigate = useNavigate()

  const [userData, setUserData] = useState({})
  const [profilePicSource, setProfilePicSource] = useState(process.env.PUBLIC_URL + "/assets/default-profile-pic.png")

  useEffect(() => {
    if (location.state===null) {
      // console.log("not supposed to be here")
      navigate("/lively/login")
    } else {
      if (location.state) {
        const docRef = doc(db, "users", location.state);
        getDoc(docRef)
          .then(doc => {
            console.log(auth.currentUser)

            setUserData(doc.data())
    
            if (doc.data().profilePicPath !== "default") {
              const storage = getStorage();
    
              const pathReference = ref(storage, doc.data().profilePicPath);

              // console.log(doc.data().profilePicPath)
              // console.log("test")
              // console.log(pathReference)
    
              getDownloadURL(pathReference)
                .then(url => setProfilePicSource(url))
                .catch(err => console.log("Error:", err))
                // .catch(err => {}) 
            }
          })
          // .catch(err => console.log(err))
          .catch(err => {}) 
      }
    }

   // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [])

  const imageControl = () => {
    if (width > 1100) {
      return {
        width: 550,
        height: 550,
        aspectRatio: 1,
        objectFit: "cover",
      }
    } else {
      return {
        width: width*0.75,
        height: width*0.75,
        aspectRatio: 1,
        objectFit: "cover",
      }
    }
  }

  return (
    <div className="fixed-flex-column-ca">
      <div className="padding-642" style={{...styles.blueContainer, textAlign: "center"}}>
        <p className="h4" style={styles.subtitle}>USER DASHBOARD</p>
        <p className="h1">Welcome, {userData.name}</p>
      </div>

      <div className="fixed-flex-column-ca padding-642 max-width-76">
        <img
            className="margin-bottom-642"
            style={{...imageControl(), ...styles.profilePic}}
            src={profilePicSource}
            alt={"Profile"}
        />
        <p className="h4" style={styles.subtitle}>USERNAME</p>
        <p className="h5 padding-bottom-42">
            @{userData.username}
        </p>

        <p className="h4" style={styles.subtitle}>EMAIL</p>
        <p className="h5 padding-bottom-42">
            {userData.email}
        </p>

        <p className="h4" style={styles.subtitle}>DOWNLOAD</p>
        <p className="h5 center-text">
            To use Lively and manage your account, download the app!
        </p>
      </div>
    </div>
  )
}

export default Account

let styles = {
    blueContainer: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: colors.superLightBlue,
        alignItems: "center",
        width: "100%"
    },
    subtitle: {
        color: colors.darkBlue,
        marginBottom: "1rem",
    },
    profilePic: {
        borderRadius: "50rem",
    },
    bigSpaceAbove: {
        marginTop: "3rem",
    },
    spaceAbove: {
        marginTop: "1rem",
    },
}