import React from "react"
import { Link } from "react-router-dom"
import useWindowDimensions from "../useWindowDimensions"

import HomePremiumFeature from "../components/HomePremiumFeature"

// import { motion } from "framer-motion"

import colors from "../styles/colors.js"
import containers from "../styles/containers.js"
import sizing from "../styles/sizing.js"
import spacing from "../styles/spacing.js"
import text from "../styles/text.js"
import Button from "../components/Button"

const Home = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0)

    const favicon = document.getElementById('favicon')
    favicon.href = "/favicon-lively.ico"
  }, [])

  document.title = "Lively | A Daily Planner and Journal to Organize Your Life"

  const { height, width } = useWindowDimensions()

  const headerWrap = () => {
    if (width > 900) {
      return {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        padding: "4rem",
        paddingBottom: "8rem",
        backgroundImage: "url(/assets/full-lighthouse-ship.png)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPosition: "bottom",
        backgroundColor: colors.superLightBlue,
      }
    } else if (width > 700) {
      return {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        padding: "3rem",
        paddingBottom: "2rem",
        backgroundColor: colors.superLightBlue,
      }
    } else {
      return {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        padding: "2rem",
        backgroundColor: colors.superLightBlue,
      }
    }
  }

  const headerTextWrap = () => {
    if (width > 900) {
      return {
        textAlign: "center",
        maxWidth: "50rem",
      }
    } else {
      return {
        textAlign: "left",
      }
    }
  }

  const topButtonControl = () => {
    if (width > 900) {
      return {
        display: "flex",
        flexDirection: "row",
        padding: "3rem",
        
      }
    } else if (width > 700) {
      return {
        display: "flex",
        flexDirection: "row",
        alignSelf: "flex-start",
        paddingTop: "3rem",
        paddingBottom: "3rem",

        // padding: "3rem",
      }
    } else {
      return {
        display: "flex",
        flexDirection: "column",
        alignSelf: "flex-start",
        paddingTop: "1.5rem",
        paddingBottom: "1.5rem",
      }
    }
  }

  const buttonSpacingControl = () => {
    if (width > 700) {
      return {
        marginTop: 0,
      }
    } else {
      return {
        marginTop: "1rem",
      }
    }
  }
  const buttonSpacingControl2 = () => {
    if (width > 700) {
      return {
        marginTop: "2rem",
      }
    } else {
      return {
        marginTop: "1rem",
      }
    }
  }

  const descriptorControl = () => {
    if (width > 1200) {
      return {
        marginRight: "8rem",
        maxWidth: "26rem"
      }
    } else if (width <= 1200 && width > 1100) {
      return { 
        marginRight: "4rem"
      }
    } else {
      return {
        marginRight: "0rem"
      }
    }
  }

  const reverseDescriptorControl = () => {
    if (width > 1200) {
      return {
        marginLeft: "8rem",
        maxWidth: "26rem"
      }
    } else if (width <= 1200 && width > 1100) {
      return { 
        marginLeft: "4rem"
      }
    } else {
      return {
        marginLeft: "0rem",
      }
    }
  }

  const imageControl = () => {
    if (width > 1100) {
      return {
        maxWidth: "50%"
      }
    } else if (width <= 1100 && width > 900) {
      return { 
        maxWidth: "75%",
        marginTop: "4rem"
      }
    } else {
      return {
        maxWidth: "100%",
        marginTop: "2rem"
      }
    }
  }

  const variant =  {
    offscreen: {
      y: 100,
      opacity: 0,
      scale: 0
    },
    onscreen: {
      y: 0,
      transition: {
        ease: [0.33, 1, 0.68, 1],
        duration: 1,
        stiffness: 50,
      },
      opacity: 1,
      scale: 1
    }
  };

  return (
    <div className="fixed-flex-column">
      <div style={headerWrap()}>

      {/* <motion.div
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.8 }}
        variants={variant}
      > */}
      <div>
        <p 
          className="h1 margin-bottom-2"
          style={headerTextWrap()}
        >
          Create an organized, smooth-sailing life with no limits.
        </p>
      </div>
      {/* </motion.div> */}
        <p className="p" style={headerTextWrap()}>
          Achieve balance between your work and life with a single app. 
          Lively is an all-in-one planner and journal that helps you 
          spend less time planning and worrying and more time enjoying 
          the things that matter.
        </p>

        <div style={{...topButtonControl()}}>
          {/* <Button action="/lively/premium" text="Get Premium" type="navLink" /> */}
          {/* <Link to="/lively/premium" className="blue-button" style={{marginRight: "3rem"}}>
            <div className="fixed-flex-cj-ca button-text">
                <p>Get Premium</p>
            </div>
          </Link> */}
          <a
            href="https://apps.apple.com/app/id1664004364"
            target="_blank" rel="noopener noreferrer"
            className="blue-button"
            style={buttonSpacingControl()}
          >
            <div className="fixed-flex-cj-ca button-text">
              <p>Download for iOS</p>
            </div>
          </a>
        </div>
      </div>

      <div className="fixed-flex-row-ca padding-642">
        <div className="row-to-column-wrap">
          <div style={{...descriptorControl(), flexDirection: "column", display: "flex"}}>
            <p className="h2 margin-bottom-1">
            Streamline your events with a dynamic calendar
            </p>
            <p className="p">
              Lively makes managing your time effortless. Schedule any 
              personal or group event in seconds, and add all the details and 
              reminders you need to never miss a thing.
            </p>
          </div>
          <img
            style={imageControl()}
            src={process.env.PUBLIC_URL + "/assets/home-features/calendar-feature.png"}
            alt={"Calendar feature"}
          />
        </div>
      </div>

      <div className="fixed-flex-row-ca padding-642 gray-bg-fill">
        {/* <motion.div
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.8 }}
        variants={variant}
      > */}
        <div className="row-to-column-reverse-wrap">

          <img
            style={imageControl()}
            src={process.env.PUBLIC_URL + "/assets/home-features/journal-feature.png"}
            alt={"Journal feature"}
          />
          <div style={{...reverseDescriptorControl(), flexDirection: "column", display: "flex"}}>
            <p className="h2 margin-bottom-1">
              Reflect on your mood and meaningful parts of your day
            </p>
            <p className="p">
              A private, safe place to keep your thoughts and 
              feelings. Take a moment to appreciate what went well 
              in your day, or let go and rant about something 
              when you need to.
            </p>
          </div>
        </div>
      {/* </motion.div> */}
      </div>

      <div className="fixed-flex-column-ca padding-642">
        <div className="row-to-column-wrap">
          <div className="centering-text-wrap">
            <p className="h2 margin-bottom-1">
              Easily organize the next meetup with your friends
            </p>
            <p className="p max-width-68 margin-bottom-42">
              Figuring out when to hang out shouldn't require an endless back-and-forth. 
              When you add friends to shared events, you'll see when they're busy, but 
              the details remain private.
            </p>
            <img
              style={{width: "100%"}}
              src={process.env.PUBLIC_URL + "/assets/home-features/sharing-feature.png"}
              alt={"Sharing features"}
            />
          </div>
        </div>
      </div>




      <div className="fixed-flex-row-ca padding-642 gray-bg-fill">
      {/* <motion.div
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.8 }}
        variants={variant}
        > */}


      
            {/* <motion.div > */}
        <div className="row-to-column-wrap">
          <div style={{...descriptorControl(), flexDirection: "column", display: "flex"}}>
            <p className="h2 margin-bottom-1">
              View your days at a glance
            </p>


            <p className="p">
              Lively keeps it simple without sacrificing the content.
              The comprehensive home tab serves as a hub that helps you 
              remember and get things done consistently.
            </p>

          </div>

          <img
            style={imageControl()}
            src={process.env.PUBLIC_URL + "/assets/home-features/home-feature.png"}
            alt={"Home screen"}
            />


        </div>
            {/* </motion.div> */}
      {/* </motion.div> */}
      </div>

      

      {/* <div className="fixed-flex-column-ca padding-642">
        <div className="column-center-to-column-wrap margin-bottom-3">
          <div className="centering-text-wrap">
            <p className="h2 margin-bottom-1">
              Add more sparkle to your life with Lively Premium
            </p>
            <p className="p" style={{marginBottom: "4rem"}}>
              Upgrade to Premium for the same Lively experience you love, 
              but with more power to achieve your goals and make the most out of every 
              day. Check out a few of the perks you'll gain access to:
            </p>
          </div>
          <HomePremiumFeature />
        </div>

        <div className="row-to-column-wrap fixed-flex-cj-ca">
          <Link
            to="/lively/signup"
            className="catchline-get-premium-container"
            style={width > 500 ? {marginRight: "4rem"} : {marginRight: 0}}
          >
              <div className="fixed-flex-cj-ca button-text" style={{width: "10rem"}}>
                  <p>Get Premium</p>
              </div>
          </Link>
          <Link to="/lively/premium" className="black-to-blue-link" style={width <= 500 ? {marginTop: "1.4rem"} : {marginTop: 0}}>
              <div>
                  <p className="h4">Learn more</p>
              </div>
          </Link>
        </div>
      </div> */}

      <div className="fixed-flex-column-ca padding-642" style={{marginBottom: "2rem"}}>
        <p className="p margin-bottom-1 center-text">
          When it comes to your life, you should be the one behind the steering wheel.
        </p>
        <p className="h2 max-width-60 center-text" style={{color: colors.darkBlue}}>
          Ready to set sail?
        </p>

        <div style={{display: "flex"}}>
          <a
            href="https://apps.apple.com/app/id1664004364"
            target="_blank" rel="noopener noreferrer"
            className="blue-button"
            style={buttonSpacingControl2()}
          >
            <div className="fixed-flex-cj-ca button-text">
                <p>Download for iOS</p>
            </div>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Home

let styles = {
  blueContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "6rem",
    backgroundColor: colors.superLightBlue,
    alignItems: "center",
  },
  catchlineDescription: {
    textAlign: "center",
    width: "50rem",
  },
  topButtonOptions: {
    display: "flex",
    flexDirection: "row",
    padding: "3rem",
  },
  downloadButtonBottom: {
    marginTop: "3rem",
  },
}