import React from "react"
import { Link } from "react-router-dom"
import useWindowDimensions from "../useWindowDimensions"

import colors from "../styles/colors.js"
import containers from "../styles/containers.js"
import sizing from "../styles/sizing.js"
import spacing from "../styles/spacing.js"
import text from "../styles/text.js"

const Download = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  React.useEffect(() => {
    const favicon = document.getElementById('favicon')
    favicon.href = "/favicon-lively.ico"
    document.title = "Download to Start Organizing Your Work and Life | Lively"
  }, [])

  const { height, width } = useWindowDimensions()

  const contentWidthControl = () => {
    if (width > 1300) {
      return {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        padding: "6rem",
        borderRadius: "2rem",
        maxHeight: "32rem",
        backgroundColor: colors.grayBackground,
        overflow: "hidden",
      }
    } else if (width > 500) {
      return {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "4rem",
        borderRadius: "2rem",
        maxHeight: "40rem",
        backgroundColor: colors.grayBackground,
        overflow: "hidden",
      }
    } else{
      return {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "3rem",
        paddingTop: "4rem",
        borderRadius: "2rem",
        maxHeight: "28rem",
        backgroundColor: colors.grayBackground,
        overflow: "hidden",
      }
    }
  }

  const contentSpacingControl = () => {
    if (width > 1300) {
      return {
        marginLeft: "6rem",
        marginTop: 0,
      }
    } else {
      return {
        marginLeft: 0,
        marginBottom: "2rem",
      }
    }
  }

  const descriptorControl = () => {
    if (width > 1300) {
      return {
        marginRight: "4rem",
        marginBottom: 0,
        maxWidth: "50%",
      }
    } else {
      return {
        marginRight: 0,
        marginBottom: "3rem",
        maxWidth: "100%",
      }
    }
  }

  const postCaption = () => {
    if (width > 1300) {
      return {
        alignSelf: "center"
      }
    } else {
      return {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      }
    }
  }

  const imageControl = () => {
    if (width > 1300) {
      return {
        maxWidth: "28rem",
      }
    } else if (width <= 1300 && width > 900) {
      return { 
        maxWidth: "75%",
      }
    } else {
      return {
        maxWidth: "100%",
      }
    }
  }

  const buttonSpacingControl2 = () => {
    if (width > 700) {
      return {
        marginTop: "2rem",
      }
    } else {
      return {
        marginTop: "1rem",
      }
    }
  }

  return (
    <div className="fixed-flex-column">
      <div className="padding-642 center-text" style={styles.blueContainer}>
        <p className="h1" style={width<=375 ? {maxWidth: "14rem"} : {}}>Download the Lively app</p>
        <p style={{...styles.spaceAbove, maxWidth: "52rem"}} className="p">
          You're so close to achieving that school, work, and life balance you've been 
          searching for. Ready to set sail? Your ship awaits!
        </p>
      </div>
      
      <div className="fixed-flex-column-ca padding-642">
        <div style={styles.container2}>
          <div className="padding-642 margin-bottom-642" style={contentWidthControl()}>
            <div style={{...postCaption(), ...descriptorControl()}}>
              <p className="h2 center-text">Get it for iPhone</p>
              <div style={{display: "flex"}}>
                <a
                  href="https://apps.apple.com/app/id1664004364"
                  target="_blank" rel="noopener noreferrer"
                  className="blue-button"
                  style={buttonSpacingControl2()}
                >
                  <div className="fixed-flex-cj-ca button-text">
                    <p>Download for iOS</p>
                  </div>
                </a>
              </div>
            </div>
            {width > 1300 && <div>
              <img
                style={{...imageControl(), ...contentSpacingControl()}}
                src={process.env.PUBLIC_URL + "/assets/download-mockups/ios.png"}
                alt={"iOS mockup"}
              />
            </div>}
            {width <= 1300 && <div style={{display: "flex", justifyContent: "center"}}>
              <img
                style={{...imageControl(), ...contentSpacingControl()}}
                src={process.env.PUBLIC_URL + "/assets/download-mockups/ios.png"}
                alt={"iOS mockup"}
              />
            </div>}
          </div>
                  
          <p className="h2">Be on the lookout...</p>
          <p className="p" style={{...styles.spaceAbove, marginBottom: "3rem"}}>
            If you aren't an iPhone user or prefer to keep things on a laptop or tablet, 
            don't worry! Even though Lively was originally made for iOS, we're working 
            hard to make Lively available on all your devices. {/* Psssst, we're coding 
            Lively for the web and Google Play right now. */} Stay tuned until then.
          </p>
        </div>
      </div>

    </div>
  )
}

export default Download

let styles = {
  container2: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "76rem",
  },
  blueContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: colors.superLightBlue,
    alignItems: "center",
  },
  subtitle: {
    color: colors.darkGray,
  },
  spaceAbove: {
    marginTop: "1rem",
  },
  catchlineButtonText: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "1.4rem",
  },
}
