import React from "react"
import { Link } from "react-router-dom"
import useWindowDimensions from "./useWindowDimensions"
import { motion } from "framer-motion"

import colors from "./styles/colors.js"
import containers from "./styles/containers.js"
import sizing from "./styles/sizing.js"
import spacing from "./styles/spacing.js"
import text from "./styles/text.js"

const BubblyHome = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0)

    const favicon = document.getElementById("favicon")
    favicon.href = "/favicon-bubbly-studios.ico"
    const lively = document.getElementById("lively")

    lively.addEventListener('click',() => {
      favicon.href = "/favicon-lively.ico"; // Set new favicon
    })
  }, [])
  
  document.title = "Bubbly Studios | Developing Exciting Apps for Everyday Life"

  const { height, width } = useWindowDimensions()

  return (
    <div
      className="fixed-flex-column-ca padding-42"
      id="bubblyHomeDiv"
    >
      <link rel="icon" href="%PUBLIC_URL%/favicon-bubbly-studios.ico" id="favicon" />
      <div className="max-width-52">
        {/* ABOUT OUR COMPANY */}
        <p className="h2 margin-bottom-1">
          Our company
        </p>
        <div className="blue-card margin-bottom-42 padding-42">
          <div className="fixed-flex-ca margin-bottom-1">
            <img
              src="../assets/bubbly-studios-logo.png"
              style={styles.logo}
              alt="Logo"
            />
            <p className="h2">Bubbly Studios</p>
          </div>
          <p className="p">
            Hi there! We're passionate about creating apps that make everyday life more 
            efficient and exciting. Check out our projects below 😊
          </p>
        </div>

        {/* ALL OUR PROJECTS */}
        <div>
          <p className="h2 margin-bottom-1">
            Our projects
          </p>

          {/* LIVELY */}
          <Link to="/lively" id="lively">
            <div className="gray-card margin-bottom-42 padding-42">
              <div className="fixed-flex-ca margin-bottom-1">
                <img
                  src="../assets/lively-logo.png"
                  style={styles.logo}
                  alt="Logo"
                />
                <p className="h2" style={{color: colors.darkBlue}}>Lively</p>
              </div>

              <p className="p italicize margin-bottom-1" style={{color: colors.black}}>
                Keeping you anchored in the present
              </p>

              <p className="p" style={{color: colors.black}}>
                A flexible organization and productivity app for journaling 
                and managing your schedule, all in one place.
              </p>
            </div>
          </Link>
        
        </div>

      </div>
    </div>
  )
}

export default BubblyHome

let styles = {
  logo: {
    height: "4rem",
    marginRight: "1.2rem",
  },
}