// colors
const colors = {
    coral: "#f07177",
    orange: "#efa651",
    gold: "fbd54f",
    greenyBaby: "76d655",
    superLightBlue: "#e7f6ff",
    lightBlue: "#b5ecff",
    brightBlue: "#79dafb",
    midBlue: "#2cabe0",
    darkBlue: "#1389bb",
    darkButtonBlue: "#1389bb",
    white: "#ffffff",
    grayBackground: "#f3f3f3",
    superLightGray: "#f6f6f6",
    lightGray: "#dcdcdc",
    inactiveGray: "#adadad",
    darkButtonGray: "#424242",
    darkGray: "#656565",
    grayBlack: "#202020",
    black: "#000000",
}

export default colors