import React from "react"
import colors from "../styles/colors.js"
import useWindowDimensions from "../useWindowDimensions"

const BlogContactSection = () => {
  const { height, width } = useWindowDimensions()

  return (
    <div className="fixed-flex-column">
        <p className="h3 margin-top-642">Contact us</p>
        <p className="p" style={{...styles.space1Above}}>
            We'd love to hear all the feedback, burning questions, and brilliant 
            ideas you have to share! If your message is related to the Lively 
            app specifically, contact <a className="blue-to-black-link" href="mailto:lively@bubblystudios.com" target="_blank">lively@bubblystudios.com</a>. Otherwise, 
            shoot us a message at <a className="blue-to-black-link" href="mailto:contact@bubblystudios.com/" target="_blank">contact@bubblystudios.com</a>.
        </p>
        <p className="p" style={{...styles.space1Above, marginBottom: "3rem"}}>
            Thank you for being here, and we hope to see you again soon! 💙
        </p>

        
    </div>
  )
}

export default BlogContactSection

let styles = {
  subtitle: {
    color: colors.darkGray,
  },
  space6Above: {
    marginTop: "6rem",
  },
  space3Above: {
    marginTop: "3rem",
  },
  space1Above: {
    marginTop: "1rem",
  },
}